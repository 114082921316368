import { Component } from "react";
import styled from "styled-components";
import { Color, media } from "../utils/style";
import RitualButton from "../components/global/RitualButton";
import Container from "../components/Container";
import Row from "../components/Row";
import Text from "../components/Text";

const PageWrapper = styled.div`
  margin-top: 80px;
  background: #ffffff;
  position: relative;

  background-image: url("/images/404-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const PageColumn = styled.div`
  padding: 120px 0;

  ${media.mobile`
    padding: 40px 0;
  `};
`;

const Title = styled.h1`
  max-width: 600px;
  font-weight: 400;
  color: ${Color.white};
  margin: 0 auto 40px;
  font-size: 84px;

  ${media.mobile`
    font-size: 50px !important;
    padding: 0 20px !important;
  `};
`;

const HomeButton = styled(RitualButton)``;

export default class NotFoundPage extends Component {
  componentDidMount() {
    this.props.updatePageData({
      label: "404",
    });
  }

  render() {
    return (
      <>
        <PageWrapper>
          <Container>
            <Row>
              <PageColumn className="col-12 col-md-8 offset-md-2 text-center">
                <Title className="p-0 text-center">
                  <Text
                    id="404.title"
                    defaultMessage="Oops! This link is broken"
                  />
                </Title>
                <HomeButton to={"/"}>
                  <Text
                    id="404.button-home"
                    defaultMessage="Return to the Homepage"
                  />
                </HomeButton>
              </PageColumn>
            </Row>
          </Container>
        </PageWrapper>
      </>
    );
  }
}
